<template>
    <div>
        <table-tpl-blocks/>
    </div>
</template>

<script>
import TableTplBlocks from '@/components/templates/blocks/table.vue'
export default {
  components: { TableTplBlocks },
    data () {
        return {
           
        }
    }
    
}
</script>